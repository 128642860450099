$main1: #783838;
$main2: #B43948;
$fc1: #333;
$fc2: #666;
$fc3: #999;

$bd1: #ddd;

$bg1: #eef3fa;
$bg2: #e50000;
$bg3: #ff6000;
$bg4: #2daefc;
$bg5: #09c15f;
$bg6: #ff6000;

.single_line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multi_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
