* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    touch-action: none;
    height: 100%;
    background-color: white;
}


html {
    line-height: inherit !important;
    font-size: 12px;
    color: #333;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: #333333;
}

@media screen and (max-width: 320px) {
    html {
        font-size: 12px
    }
}

@media screen and (min-width: 320px) and (max-width: 540px) {
    html {
        font-size: 14px;
    }
}

@media screen and (min-width: 540px) {
    html {
        font-size: 16px
    }
}

