@charset "UTF-8";
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

.state0 {
  /* 即将截拍 */
  background-color: #e50000;
  color: white; }

.state1 {
  /* 拍卖中 */
  background-color: #ff6000;
  color: white; }

.state2 {
  /* 预展中 */
  background-color: #2daefc;
  color: white; }

.state3 {
  /* 已截拍 */
  background-color: #dddd;
  color: #333; }

.state4 {
  /* 已截拍 */
  background-color: rgba(0, 0, 0, 0.5);
  color: white; }

.state5 {
  color: white;
  background-color: #783838; }

.state6 {
  color: white;
  background-color: #B43948; }

.green {
  color: #09c15f; }

.price1 {
  color: #e50000; }

.price2 {
  color: #ff6000; }

.main {
  color: #783838; }

.main2 {
  color: #B43948; }

.btn {
  line-height: 3rem;
  text-align: center;
  border-radius: .5rem; }

.bg1 {
  background-color: #eef3fa; }

.bdc {
  border-color: #ddd; }

.bd_u, .bd_ud {
  border-top: 1px solid #ddd; }

.bd_d, .bd_ud {
  border-bottom: 1px solid #ddd; }

.bd_lr {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd; }

.am-flexbox {
  overflow: inherit !important; }

.am-flexbox.am-flexbox-dir-column {
  align-items: inherit !important;
  height: 100% !important; }

.am-flexbox .am-flexbox-item {
  overflow: hidden; }

.padding_lr, .padding {
  padding-left: .5rem;
  padding-right: .5rem; }

.padding_ud, .padding {
  padding-top: .5rem;
  padding-bottom: .5rem; }

.item {
  background-color: white; }

.margin_u, .margin_ud {
  margin-top: .5rem; }

.margin_d, .margin_ud {
  margin-bottom: .5rem; }

.margin_lr {
  margin-left: .5rem;
  margin-right: .5rem; }

.col_1 {
  color: #333; }

.col_2 {
  color: #666; }

.col_3 {
  color: #999; }

.single_line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
