@import "../../sass/consts";

.home_img {
  z-index: 100;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: .2rem;
  background-color: $main2;
  border-radius: .3rem;

  img {
    height: 1.5rem;
  }
}